import React from 'react';

import { useEventQuery } from '@querys/useEventQuery';
import { StandardCarousel } from '../pageModules/carousels/standardCarousel';
import { usePortalQuery } from '@querys/usePortalQuery';

export const OtherEvents = ({ currentSlug }) => {
  const { eventPortal } = usePortalQuery();
  const { nodes } = useEventQuery();

  //filter currentSlug from nodes
  const filteredNodes = nodes
    ?.filter(
      (node) =>
        node?.slug?.current !== currentSlug &&
        new Date(node.endDate) > new Date(),
    )
    .slice(0, 6);
  const slider = { slides: filteredNodes };

  return (
    <div className='pt-space-m pb-space-m relative z-10'>
      <StandardCarousel
        slider={slider}
        label='Other events'
        viewAll={eventPortal?.slug?.current}
      />
    </div>
  );
};
