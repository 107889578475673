import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { OtherEvents } from '@components/portals/otherEvents';

const Intro = loadable(() => import('../components/portals/concerts/intro'));
const Sessions = loadable(() =>
  import('../components/portals/concerts/sessions'),
);
const Content = loadable(() =>
  import('../components/portals/concerts/content'),
);

const EventTemplate = ({ data: { page } }) => {
  const { seo, slug } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      <Intro {...page} />
      <Sessions {...page} />
      <Content {...page} />
      <OtherEvents currentSlug={slug?.current} />
    </Layout>
  );
};

export default EventTemplate;

export const simpleEventQuery = graphql`
  query standardEventQuery($slug: String!) {
    page: sanityEvent(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      series
      slug {
        current
      }
      excerpt
      featuredImage {
        ...ImageWithPreview
      }
      dateRange
      startDate
      specialConcert
      sessions {
        ticketsLink
        time(formatString: "DD MMMM YYYY - hh:mm a")
        timeString
        isSoldOut
        doorSales
        comingSoon
        smallVenue
        smallVenueName
        smallVenueLocation
        venue {
          title
          location
          slug {
            current
          }
        }
      }
      programme
      otherArtists
      featuredArtists {
        name
        position
        slug {
          current
        }
      }
      supporters {
        image {
          ...ImageWithPreview
        }
        title
        url
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
