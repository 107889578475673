import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useEventQuery = () => {
  const { allSanityEvent } = useStaticQuery(graphql`
    query {
      allSanityEvent(sort: { fields: [startDate], order: ASC }) {
        nodes {
          title
          slug {
            current
          }
          featuredImage {
            ...ImageWithPreview
          }
          dateRange
          startDate
          endDate
          excerpt
          sessions {
            smallVenueLocation
            doorSales
            comingSoon
            ticketsLink
            venue {
              location
              title
            }
            time(locale: "NZ", formatString: "DD MMMM YYYY hh:mm A")
          }
          featuredArtists {
            name
            position
          }
          series
          specialConcert
        }
      }
    }
  `);
  return allSanityEvent || {};
};
